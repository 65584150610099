import axios from "axios";

export const displayitemsApi={

  handleAddItems: function(data) {
    return axios.post("/a1/displayitems/add", data);
  },

  handleUpdateItems: function(data) {
    return axios.post("/a1/displayitems/update", data);
  },

  handleDeleteItem: function(params) {
    return axios.delete("/a1/displayitems/delete", { params });
  },
  handleGetInfoDisplayItems: function(id) {
  },

  handleGetAllDisplayItems: function(params) {
    return axios.get(`/a1/displayitems/list`, { params });
  },
  handleAddDisplayDetail: function(data) {
    return axios.post("/a1/displayitems/detail/add", data);
  },
  handleUpdateDisplayDetail: function(data) {
    return axios.post("/a1/displayitems/detail/update", data);
  },
  handleDeleteDisplayDetail: function(id) {
    return axios.delete(`/a1/displayitems/detail/delete`, { params: { detailid: id } });
  },
  handleGetAllDisplayDetail: (data) => {
    return axios.get(`/a1/displayitems/detail/list`, { params: data });
  },

};
