<template>
  <div class="display-new">
    <div class="header">
      <el-button @click="showAddDisplayWrap = true;isAdd = true">添加</el-button>
    </div>
    <div class="content">
      <el-table :data="displayData">
        <el-table-column label="id" prop="id"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="展示图" prop="image_url">
          <template v-slot="{row}">
            <el-image :src="row['image_url']" style="width: 60px;height: 60px" :preview-src-list="[row['image_url']]" />
          </template>
        </el-table-column>
        <el-table-column label="附件" prop="image_url">
          <template v-slot="{row}">
<!--            <el-image :src="row['file_url']" style="width: 60px;height: 60px" :preview-src-list="[row['image_url']]" />-->
            <el-link :href="row['file_url']"> {{ row['file_url'] }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="位置/类型" prop="position">
          <template v-slot="{row}">
            {{ positionArr[row['position']] }}
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort"></el-table-column>
        <el-table-column label="是否展示" prop="status">
          <template v-slot="{row}">
            {{ row['status'] === 1? '展示':'不展示' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240px">
          <template v-slot="{row}">
            <el-button @click="handleEditDisplay(row)">编辑</el-button>
            <el-button @click="handleAddDisplay(row)">查看列表</el-button>
            <el-button @click="handleDeleteDisplay(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div ref="displayData-footer" class="displayData-footer" v-if="displayData.length > 0">
        <el-pagination :current-page="displayDataPageInfo.page"
                       :page-sizes="[10, 20, 50, 100, 500, 1000]"
                       :page-size="displayDataPageInfo.pagesize"
                       layout="total,sizes, prev, pager, next, jumper"
                       :total="displayDataPageInfo.total"
                       @current-change="handleChangeCurrentPage"
                       @size-change="handleSizeChange"
                       background />
      </div>
    </div>

    <el-dialog title="陈列列表" :visible.sync="showAddDisplayWrap" @close="handleResetAddDisplay">
      <el-form label-width="120px">
        <el-form-item label="标题">
          <el-input v-model="addDisplayForm.title"></el-input>
        </el-form-item>
        <el-form-item label="位置/类型">
<!--          <el-input v-model="addDisplayForm.position"></el-input>-->
          <el-select v-model="addDisplayForm.position" placeholder="请选择">
            <el-option v-for="item in [{label:'banner图',value:1},{label: '系列展示',value:2},{label:'模板展示', value:3}]" :value="item.value" :label="item.label" :key="item.value">{{ item.label }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="addDisplayForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="是否展示">
          <el-switch
            v-model="addDisplayForm.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="展示"
            inactive-text="不展示"
            :active-value=1
            :inactive-value=2>
          </el-switch>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
            class="avatar-uploader"
            :action="this.$root.DownUrl + '/a1/upload/file'"
            :headers="uploadHeaders"
            :show-file-list="false"
            :on-success="handleImgSuccess"
            :auto-upload="true"
            list-type="picture"
            :on-progress="handleDetailProgress"
          >
            <img v-if="addDisplayForm.image_url" :src="addDisplayForm.image_url" class="avatar" alt="" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="附件">
          <el-upload
            class="avatar-uploader"
            :action="this.$root.DownUrl + '/a1/upload/file'"
            :headers="uploadHeaders"
            :show-file-list="true"
            :on-success="handleFileSuccess"
            :auto-upload="true"
            multiple
            :limit="1"
            :file-list="fileList"
            :on-progress="handleDetailProgress"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				  <el-button @click="showAddDisplayWrap = false">取 消</el-button>
				  <el-button type="primary" @click="submitDisplayWrap" v-if="isAdd">提 交</el-button>
				  <el-button type="primary" @click="editDisplayWrap" v-if="!isAdd">编 辑</el-button>
			  </span>
    </el-dialog>

    <el-drawer
      title="添加详情"
      :visible.sync="showDetailWrap"
      direction="rtl"
      :wrapperClosable="false"
      size="60%">
      <div class="add-wrap">
        <el-form label-width="120px" :inline="true">
          <el-form-item label="位置">
            <el-select v-model="addDisplayDetailForm.position" placeholder="请选择">
              <el-option v-for="item in [{label:'banner',value:1},{label:'列表',value:2}]" :label="item.label" :value="item.value" :key="item.value">{{ item.label }}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="addDisplayDetailForm.title"></el-input>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="addDisplayDetailForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="是否展示">
            <el-switch
              v-model="addDisplayDetailForm.status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="展示"
              inactive-text="不展示"
              :active-value=1
              :inactive-value=2>
            </el-switch>
          </el-form-item>
          <el-row>
            <el-form-item label="图片">
              <el-upload
                class="avatar-uploader"
                :action="this.$root.DownUrl + '/a1/upload/file'"
                :headers="uploadHeaders"
                :show-file-list="true"
                :on-success="handleDetailImgSuccess"
                :auto-upload="true"
                list-type="picture"
                :on-progress="handleDetailProgress"
              >
                <img v-if="addDisplayDetailForm.image_url" :src="addDisplayDetailForm.image_url" class="avatar" alt="" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-row>
          <el-row v-if="addDisplayDetailForm.position === 1">
<!--            <el-form-item label="视频">-->
<!--              <el-upload-->
<!--                class="avatar-uploader"-->
<!--                :action="this.$root.DownUrl + '/a1/upload/file'"-->
<!--                :headers="uploadHeaders"-->
<!--                :show-file-list="true"-->
<!--                :on-success="handleDetailVedioSuccess"-->
<!--                :auto-upload="true"-->
<!--                multiple-->
<!--                :limit="1"-->
<!--                :file-list="detailfileList"-->
<!--                accept=".mp4"-->
<!--                :on-progress="handleDetailProgress"-->
<!--              >-->
<!--                <el-button size="small" type="primary">点击上传</el-button>-->
<!--              </el-upload>-->
<!--            </el-form-item>-->
            <el-form-item label="视频地址">
              <el-input v-model="addDisplayDetailForm.file_url"></el-input>
            </el-form-item>
          </el-row>
          <el-row v-if="addDisplayDetailForm.position === 1">
            <el-form-item label="视频封面" >
              <el-upload
                class="avatar-uploader"
                :action="this.$root.DownUrl + '/a1/upload/file'"
                :headers="uploadHeaders"
                :show-file-list="true"
                :on-success="handleVidelCoverSuccess"
                :auto-upload="true"
                list-type="picture"
                :on-progress="handleDetailProgress"
              >
                <img v-if="addDisplayDetailForm.file_cover" :src="addDisplayDetailForm.file_cover" class="avatar" alt="" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-row>
          <br>
          <el-row>
            <el-button style="margin-left: 80px" @click="handleAddDisplayDetail">添加</el-button>
          </el-row>
        </el-form>
      </div>
      <div class="detail-content">
        <el-table :data="displayDetailData" height="550px">
<!--          <el-table-column label="id" prop="id"></el-table-column>-->
          <el-table-column label="位置" prop="position">
            <template v-slot="{row}">
              <div> {{ row['position'] === 1 ? 'banner' : '列表' }} </div>
            </template>
          </el-table-column>
          <el-table-column label="排序" prop="sort"></el-table-column>
          <el-table-column label="是否展示" prop="status">
              <template v-slot="{row}">
                <div> {{ row['status'] === 1 ? '展示' : '不展示' }} </div>
              </template>
          </el-table-column>
          <el-table-column label="图片" prop="images_url">
            <template v-slot="{row}">
              <el-image :src="row['image_url']" :alt="row['title']" style="height: 60px;width: 60px" />
            </template>
          </el-table-column>
          <el-table-column label="视频" prop="file_url" width="500px">
            <template v-slot="{row}">
              <div v-if="row['position'] === 1">
                <video width="100%" height="50%" controls preload="auto"
                       :src="row['file_url']" type="video/mp4">
                </video>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160px">
            <template v-slot="{row}">
              <el-button @click="handleEditDisplayDetail(row)">编辑</el-button>
              <el-button @click="handleDeleteDisplayDetail(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>

    <el-dialog title="编辑详情" :visible.sync="editdisplaydetailWrap" @close="handleCloseEditDisplayDetail" :close-on-click-modal="false">
      <el-form label-width="120px">
        <el-form-item label="标题">
          <el-input v-model="addDisplayDetailForm.title"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="addDisplayDetailForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="是否展示">
          <el-switch
            v-model="addDisplayDetailForm.status"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="展示"
            inactive-text="不展示"
            :active-value=1
            :inactive-value=2>
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editdisplaydetailWrap = false">取 消</el-button>
        <el-button type="primary" @click="handleUpdateDisplayDetail" v-if="!isAdd">编 辑</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { displayitemsApi } from "@/api/admin/displayitems";

export default {
  name: "displaynew",
  data(){
    return{
      displayData:[],
      showAddDisplayWrap:false,
      isAdd:false,
      addDisplayForm:{
        id:'',
        title:'',
        position:'',
        sort:'',
        image_url:'',
        status:1,
        file_url:''
      },
      fileList:[],
      positionArr:{1:'banner图',2:'系列展示',3:'模板展示'},
      uploadHeaders: {
        Authorization: JSON.parse(sessionStorage.getItem("token")),
      },
      displayDetailData:[],
      showDetailWrap:false,
      addDisplayDetailForm:{
        itemsid:'',
        id:'',
        title:'',
        image_url:'',
        file_url:'',
        file_cover:'',
        sort:'',
        status:1,
        position:''
      },
      detailfileList:[],
      fileCoverList:[],
      editdisplaydetailWrap:false,
      displayDataPageInfo: {
        page: 1,
        pagesize: 10,
        total: 0,
      },
    }
  },
  created() {
    this.handleGetInfoDisplayItems()
  },
  methods:{
    handleGetInfoDisplayItems(){
      displayitemsApi.handleGetAllDisplayItems({page:this.displayDataPageInfo.page,page_size:this.displayDataPageInfo.pagesize}).then(res => {
        this.displayData = res.data.data["data"];
        this.displayDataPageInfo.total = res.data.data['total'];
      })
    },
    handleResetAddDisplay(){
      this.addDisplayForm = this.$options.data().addDisplayForm
    },
    handleImgSuccess(res, file){
      if (res.error === 0) this.addDisplayForm.src=JSON.parse(JSON.stringify(res.data));
      this.addDisplayForm.image_url=file.response.data;
    },
    submitDisplayWrap(){
      this.addDisplayForm.position = +this.addDisplayForm.position;
      this.addDisplayForm.sort = +this.addDisplayForm.sort;
      displayitemsApi.handleAddItems(this.addDisplayForm).then((res) =>{
        if (res.data["error"] === 0){
          this.$message.success("添加成功")
          this.handleGetInfoDisplayItems()
          this.showAddDisplayWrap = false;
        }else{
          this.$message.error(res.data["message"])
        }
      }).catch((err) =>{
        this.$message.error(err.response.data.message)
      })
    },
    editDisplayWrap(){
      this.addDisplayForm.position = +this.addDisplayForm.position;
      this.addDisplayForm.sort = +this.addDisplayForm.sort;
      displayitemsApi.handleUpdateItems(this.addDisplayForm).then((res) =>{
        if (res.data["error"] === 0){
          this.$message.success("添加成功")
          this.handleGetInfoDisplayItems()
          this.showAddDisplayWrap = false;
        }else{
          this.$message.error(res.data["message"])
        }
      }).catch((err) =>{
        this.$message.error(err.response.data.message)
      })

    },
    handleEditDisplay(row){
      this.addDisplayForm = JSON.parse(JSON.stringify(row));
      this.showAddDisplayWrap = true;
      this.isAdd = false;
    },
    handleAddDisplay(row){
      this.showDetailWrap = true;
      this.addDisplayForm = JSON.parse(JSON.stringify(row));
      this.addDisplayDetailForm = this.$options.data().addDisplayDetailForm
      this.handleGetAllDisplayDetail(row.id)
    },
    handleDeleteDisplay(row){
      displayitemsApi.handleDeleteItem({ id:row.id }).then((res) =>{
        this.$message.success("删除成功")
        this.handleGetInfoDisplayItems()
      })
    },
    handleDetailImgSuccess(res,file){
      if (res.error === 0) this.addDisplayDetailForm.src=JSON.parse(JSON.stringify(res.data));
      this.addDisplayDetailForm.image_url=file.response.data;
    },
    handleDetailVedioSuccess(res,file){
      if (res.error === 0) this.addDisplayDetailForm.src=JSON.parse(JSON.stringify(res.data));
      this.addDisplayDetailForm.file_url=file.response.data;
      this.detailfileList = [{name:file.name,url:file.response.data}];
      this.$message.success("上传成功")
    },
    handleVidelCoverSuccess(res,file){
      if (res.error === 0) this.addDisplayDetailForm.src=JSON.parse(JSON.stringify(res.data));
      this.addDisplayDetailForm.file_cover=file.response.data;
      this.fileCoverList = [{name:file.name,url:file.response.data}];
      this.$message.success("上传成功")
    },
    handleDetailProgress(){
      this.$message.info("正在上传请稍后")
    },
    handleAddDisplayDetail(){
      this.addDisplayDetailForm.itemsid = this.addDisplayForm.id;
      this.addDisplayDetailForm.sort = +this.addDisplayDetailForm.sort;
      displayitemsApi.handleAddDisplayDetail(this.addDisplayDetailForm).then((res) =>{
        this.handleGetAllDisplayDetail(this.addDisplayDetailForm.itemsid)
        this.addDisplayDetailForm = this.$options.data().addDisplayDetailForm
      })
    },
    handleGetAllDisplayDetail(itemsid){
      displayitemsApi.handleGetAllDisplayDetail({itemsid:itemsid}).then(res =>{
        this.displayDetailData = res.data.data["data"];
      })
    },
    handleDeleteDisplayDetail(row){
      displayitemsApi.handleDeleteDisplayDetail(row.id).then(res =>{
        this.$message.success("删除成功")
        this.handleGetAllDisplayDetail(this.addDisplayForm.id)
      })
    },
    handleEditDisplayDetail(row){
      this.editdisplaydetailWrap = true;
      this.addDisplayDetailForm = JSON.parse(JSON.stringify(row));
    },
    handleUpdateDisplayDetail(){
      this.addDisplayDetailForm.sort =  +this.addDisplayDetailForm.sort;
      displayitemsApi.handleUpdateDisplayDetail(this.addDisplayDetailForm).then(res =>{
        this.$message.success('修改成功');
        this.editdisplaydetailWrap = false
        this.handleGetAllDisplayDetail(this.addDisplayDetailForm.itemsid)
      })
    },
    handleCloseEditDisplayDetail(){
      this.addDisplayDetailForm = this.$options.data().addDisplayDetailForm
    },
    handleFileSuccess(res,file){
      if (res.error === 0) this.addDisplayForm.src=JSON.parse(JSON.stringify(res.data));
      this.addDisplayForm.file_url=file.response.data;
      this.fileList = [{name:file.name,url:file.response.data}];
    },
    handleSizeChange(val) {
      this.displayDataPageInfo.pagesize=val;
      this.handleGetInfoDisplayItems()
    },
    handleChangeCurrentPage(page) {
      this.displayDataPageInfo.page=page;
      this.handleGetInfoDisplayItems()
    },
  }
};
</script>

<style scoped lang="scss">
@import "./css/index.scss";
.display-new {
  padding: 20px;
  .detail-content{
    padding: 10px;
  }
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
